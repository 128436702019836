.skills{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.skills-section{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.skills-list{
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 24px 0px;
}
@media only screen and (max-width: 720px){
    .skills-list{
        max-width: 100%;
        justify-content: center;
    }
    .skills-section{
        flex-direction: column;
        align-items: center;
    }
}