.web{
    display: flex;
    flex: 1;
    align-items: center;
}
.web-option{
    font-size: 20px;
    margin-right: 16px;
    font-weight: 700;
}

.web-option a{
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
}
.web-option img{
    display: flex;
    align-items: center;
    margin-right: 4px;
}
.web-option :last-child{
    margin-right: 0px;
}
