.header {
    display: flex;
    justify-content: space-around;
    flex: 1;
    padding: 12px;
    position: relative;
    align-items: center;
    background-color: lightblue;
}
.menu{
    display: flex;
    flex: 1;
    justify-content: flex-end;
}
.menu-icon{
    font-size: 24px;
}
.mobile-menu{
    display: none;
}
.web-menu{
    display: flex;
}
.logo{
    display: flex;
    font-size: 36px;
    color: rgb(40, 95, 196);
    font-weight: 800;
}
@media only screen and (max-width: 720px){

.mobile-menu{
    display: flex;
}
.web-menu{
    display: none;
}
.logo{
    font-size: 30px;
}
}