.about{
    padding-bottom: 8px;
}
.about-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.profile-image{
    height: 300px;
    border-radius: 20px;
}
.about-info{
    margin : 10px 0px;
    font-size: 24px;
}
.info-name{
    font-size: 32px;
    font-weight: 800;
}
p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color : #868e96!important;
    font-size: 20px;
    line-height: normal;
    margin-right: 5px;
}
/* .testing-list{
    font-size : 18px;
    margin-top: 30px;
    text-indent: -2em;
    line-height: .8;
} */

@media only screen and (max-width: 720px){
    .about-top{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .profile-image{
        height: 280px;
    }
    .about-info{
        font-size: 20px;
    }
    .info-name{
        font-size: 26px;
    }
}