.contact {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.section-title {
  font-size: 26px;
  font-weight: 700;
}
.contact-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0px;
}
img{
  vertical-align: -0.3em;
}
.contact-left {
  max-width: 60%;
  font-size: 20px;
  text-align: center;
}
.download {
  margin-right: 35px;
  margin-left: 35px;
}
.download :hover{
  background-color: #868e96;
}
.download-link{
  text-decoration: none;
  color: black;
  font-size: 20px;
  background-color: rgb(121, 185, 245);
  padding: 8px 16px;
  font-size: 20px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

@media only screen and (max-width: 720px) {
  .contact-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-left {
    max-width: 100%;
    font-size: 16px;
    margin-bottom: 12px;
  }
.download {
    font-size: 16px;
}  
}
