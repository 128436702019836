.skill-card{
    width: max-content;
    padding: 6px 1px;
    height: 120px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4px;
    border-radius: 8px;
}
.skill-icon{
    font-size: 50px;
}
.skill-name{
    font-size: 18px;
}
@media only screen and (max-width: 720px){
    .skill-card{
        width: max-content;
        height: 100px;
    }
    .skill-icon{
        font-size: 60px;
    }
}