.footer {
  background-color: lightblue;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  font-size: 15px;
  font-weight: 500;
}
